/* faq section */

.faq-section {
  padding: 3rem 0;
  grid-column: 1 / -1;
  background-size: cover;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}

.faq-section h1 {
  grid-column: c / o;
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  margin-bottom: var(--space-2);
  font-weight: bold;
}

.faq-section .faq {
  grid-column: c / k;
}

.faq .details {
  padding: 0.75rem;
  padding: 0.75rem;
  background: var(--color-2-dark);
  color: #fff;
  box-shadow: inset 0 -1px #00000080;
  transition: all 0.5s ease-out;
}
.faq .details[open] {
  background: var(--color-2-darker);
}

.faq .faq-question {
  list-style: none;
  cursor: pointer;
  font-weight: bold;
  line-height: 1rem;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.75rem;
}

.faq .faq-question h3 {
  font-size: 1.375rem;
  line-height: 1.5rem;
  flex-grow: 1;
}

.faq .faq-question .indicator {
  transform: scaleX(-1) rotate(90deg);
  position: relative;
  transition: all 0.75s ease-out;
  font-size: 1.5rem;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.faq .faq-answer {
  padding: var(--space-1) 0 0 0;
}
.faq details[open] .faq-question .indicator {
  transform: scaleX(-1) rotate(180deg);
}

/* mobile styling */
@media only screen and (max-width: 480px) {
  .faq-section h1,
  .faq-section .faq {
    grid-column: b / p;
  }

  .crew-section {
    --crew-card-size: 15rem;
  }
}
/* tablet styling */
@media only screen and (min-width: 481px) and (max-width: 768px) {
}

/* desktop styling */
@media only screen and (min-width: 768.1px) {
}
details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
