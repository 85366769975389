.bg-black-opacity-25 {
  background-color: #00000080;
}
.tinymceview hr {
  margin: 1.5rem;
  border-color: #00000040;
}
.tinymceview hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.tinymceview ul,
.tinymceview ol {
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.tinymceview ul {
  list-style: disc;
}
.tinymceview ol {
  list-style: decimal;
}
