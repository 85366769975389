.navtab {
  background: var(--color-button-bg);
  border: 1px solid var(--color-button-border);
  font-weight: 800;
  font-size: var(--font-size-600);
  line-height: var(--line-height-600);

  color: var(--color-button-text);
  padding: 0.5rem 0.75rem;
  display: inline-block;
  display: inline-flexbox;
  display: block;
  transition: all 0.125s;
  align-items: stretch;
  align-content: stretch;
}

.navtab > * {
  margin: 0 0.125rem;
}
.navtab:hover {
  background: gold;
}
.navtab--ghost {
  background: none;
  border: 1px solid transparent;
}

.navtab--ghost:hover {
  background: var(--color-ghost-navtab-bg-hover);
}

.navtab--3d {
  background: var(--color-3d-button-bg);
  border: 1px solid var(--color-3d-button-border);
  box-shadow: 0 7px 0 0 var(--color-3d-button-border);

  transform: translateY(-8px);
}

.navtab--3d:hover {
  background: var(--color-3d-button-bg-hover);
}

.navtab--3d:active,
.navtab--3d.active {
  transform: translateY(-4px);
  box-shadow: 0 2px 0 0 var(--color-3d-button-border);
  transition: all 0s;
}
.navtab--3d.active {
  background: orange;
  background: hsl(176, 56%, 33%, 1);
  color: white;
}

@media only screen and (max-width: 480px) {
  .navtab {
    display: block;
  }
  .navtab:hover {
  }
}

@media only screen and (min-width: 768px) {
}
