:root {
  --color-button-bg: hsla(0, 0%, 95%, 1);
  --color-button-bg-hover: hsla(0, 0%, 85%, 1);
  --color-button-border: #000000ff;
  --color-button-text: #000000ff;

  --color-ghost-button-bg-hover: hsla(0, 0%, 0%, 0.1);

  --color-3d-button-bg: hsla(176, 56%, 55%, 1);
  --color-3d-button-bg-hover: hsla(176, 56%, 65%, 1);
  --color-3d-button-border: #000000;
}

.button {
  background: var(--color-button-bg);
  border: 1px solid var(--color-button-border);
  font-weight: 800;
  font-size: var(--font-size-600);
  line-height: var(--space-2);
  box-sizing: border-box;
  cursor: pointer;

  color: var(--color-button-text);
  padding-block: var(--space-066);
  padding-inline: var(--space-066);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-033);
  transition: all 0.125s;
  white-space: nowrap;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.button:focus {
}
.button span {
  line-height: var(--space-2);
  height: var(--space-2);
  display: inline-flexbox;
}
.button i {
  width: var(--space-2);
  height: var(--space-2);
  display: inline-flexbox;
  line-height: var(--space-2);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.button svg {
  width: var(--space-rem);
  height: var(--space-rem);
  margin: var(--space-033);
  fill: currentColor;
}

.button svg.fontawesome {
  width: 1rem;
  height: 1rem;
  margin: var(--space-033);
}

.button:hover {
  background: var(--color-button-bg-hover);
}
.button--ghost {
  background: none;
  border: 1px solid transparent;
}

.button--ghost:hover {
  background: var(--color-ghost-button-bg-hover);
}

.button--3d {
  background: var(--color-3d-button-bg);
  border: 1px solid var(--color-3d-button-border);
  box-shadow: 0 7px 0 0 var(--color-3d-button-border);

  transform: translateY(-8px);
  padding-block: var(--space-066);
  padding-inline: var(--space-066);
}

.button--3d:hover {
  background: var(--color-3d-button-bg-hover);
}

.button--3d:active {
  transform: translateY(-3px);
  box-shadow: 0 2px 0 0 var(--color-3d-button-border);
  transition: all 0s;
}

@media only screen and (min-width: 481px) {
  .button--3d.megalo {
    font-size: 1.6875rem;
    line-height: 2.25rem;
    padding: 0.8rem;
  }
}
.button--orange {
  --color-3d-button-bg: hsla(28, 100%, 53%, 1);
  --color-3d-button-bg-hover: hsla(28, 100%, 65%, 1);
  --color-3d-button-border: #000000;
}
.button--green {
  --color-3d-button-bg: hsl(72, 75%, 50%, 1);
  --color-3d-button-bg-hover: hsl(72, 75%, 65%, 1);
  --color-3d-button-border: #000000;
}
.button--buy {
  --color-3d-button-bg: hsla(28, 100%, 53%, 1);
  --color-3d-button-bg-hover: hsla(28, 100%, 65%, 1);
  --color-3d-button-border: #000000;
}
