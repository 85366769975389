/* 

HELP

*/
/* Layout-independent styling of components */

/* Layouts */

.help {
  padding-top: 6em;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-auto-rows: minmax(min-content, max-content);
  grid-column-gap: var(--grid-gap);
}

.help-tabs {
  grid-column: c / o;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  flex-grow: 0;
  margin: auto;
  flex-wrap: nowrap;
}
.help-contents {
  grid-column: c / l;
  justify-content: center;
  align-items: center;
  gap: 0;
  flex-grow: 0;
  margin: auto;
  flex-wrap: nowrap;
  padding-top: var(--space-1);
}

@media only screen and (max-width: 480px) {
  .help-contents {
    grid-column: b / p;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
}
@media only screen and (min-width: 769px) {
}
