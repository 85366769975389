/* 

  MY PAGE

*/
/* Layout-independent styling of components */

/* Layouts */

.mypage {
  padding-top: 6em;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-auto-rows: minmax(min-content, max-content);
  grid-column-gap: var(--grid-gap);
}

.mypage-tabs {
  grid-column: c / o;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  flex-grow: 0;
  margin: auto;
  flex-wrap: nowrap;
}
/* Program list */

.mypage-programs {
  list-style: none;
  grid-column: e / o;
  margin-top: 3rem;
}
.mypage-program {
  display: flex;
  padding-block: 0.5rem;
  border-bottom: 1px solid #ffffff80;
}
.mypage-program .program-link {
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 0;
  column-gap: 0;
  color: var(--color-5);
  flex-grow: 1;
  justify-items: stretch;
  align-items: start;
}
.mypage-program a.program-link:hover {
  color: var(--color-5-bright);
}

.mypage-program .mypage-progam-image {
  grid-column: 1 / 1;
  grid-row: 1 / span 2;
  max-width: 3rem;
  aspect-ratio: 1 / 1;
  display: block;
  object-fit: cover;
}

.mypage-program .mypage-program-type {
  grid-column: 2 / -1;
  grid-row: 1 / span 1;
  padding: 0rem 0.75rem 0.25rem 0.75rem;
  font-size: var(--font-size-sm);
  line-height: 0.75rem;
  text-transform: uppercase;
  display: block;
}
.mypage-program .mypage-program-title {
  grid-column: 2 / 2;
  grid-row: 2 / 2;
  padding-inline: 0.75rem;
  font-size: var(--font-size-400);
  line-height: 1rem;
  display: block;
  font-weight: 700;
  align-self: start;
}
.mypage-program-actions {
  flex-wrap: nowrap;
  flex-shrink: 0;
  padding-top: 0.5rem;
}

.mypage-program--expiring a.program-link {
  color: var(--color-1);
}

.mypage-program--expiring a.program-link:hover {
  color: var(--color-1-bright);
}

.mypage-program--expired a.program-link {
  color: var(--color-2);
}

.mypage-program--expired a.program-link:hover {
  color: var(--color-2-bright);
}

/* receipts list */

.mypage-receipts {
  list-style: none;
  grid-column: f / n;
  margin-top: 3rem;
}

.mypage-receipt {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0.5rem 0.5rem 0.75rem 0.5rem;
  color: black;
  margin-bottom: 0.75rem;
  position: relative;
  gap: 0;
}

.mypage-receipt-actions {
  grid-row: 1 / 1;
  grid-column: 2 / -1;
  align-self: center;
  justify-self: right;
}

.mypage-receipt h3 {
  padding-inline: 0.5rem;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  text-transform: uppercase;
  align-self: center;
}

.mypage-receipt-products {
  grid-column: 1 / -1;
  grid-row: 2 / 2;
  padding-bottom: 0.5rem;
  padding-inline: 0.5rem;
  font-weight: 700;
  font-size: var(--font-size-600);
  line-height: var(--line-height-600);
}

.mypage-receipt-total {
  padding-inline: 0.5rem;
  grid-column: 1 / -1;
  grid-row: 3 / 3;
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: var(--font-size-600);
  line-height: var(--line-height-600);
}

/* account info */

.mypage-account {
  grid-column: f / n;
  margin-top: 3rem;
}

.account-section {
  border-bottom: 1px solid #ffffff80;
  padding-block: 1.5rem;
}
.account-section .heading2 {
  margin-bottom: 0.75rem;
}

.account-personal-data {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.75rem;
}

.account-personal-data dt {
  font-weight: bold;
  grid-column: 1 / 1;
}
.account-personal-data dd {
  grid-column: 2 / -1;
}

/* communication options */
.account-communication {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.75rem;
}

.account-communication input {
  font-weight: bold;
  grid-column: 1 / 1;
}
.account-communication label {
  grid-column: 2 / -1;
}
@media only screen and (max-width: 480px) {
  .mypage-programs,
  .mypage-receipts,
  .mypage-account {
    grid-column: b / p;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
}
@media only screen and (min-width: 769px) {
}
