body {
  background: #282030;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@import url("https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,100..900;1,100..900&display=swap");
.gridless {
  grid-column: 1 / -1;
  margin-top: 6rem;
}
input {
  color: #101010;
}
.heptagon {
  flex-grow: 0;
  flex-shrink: 1;
  clip-path: polygon(
    0% 35%,
    28% 0%,
    72% 0,
    100% 35%,
    90% 78%,
    50% 98%,
    10% 78%
  );
}
.frontimg {
  width: 300vh;
  height: 110vh;
}
.frontimgwrapper {
  height: 75vh;
}
#root {
  padding-bottom: 16rem;
}
.start-logged-in,
program-page {
  padding-bottom: 12rem;
}
body.offline .home {
  filter: grayscale(0.9);
}
textarea {
  color: #282030;
}
.border-hover {
  border-top: 0px #32864e solid;
  margin-top: 6px;
  transition: all 0.2s ease-in-out;
  height: 40px;
}
.border-hover.active {
  margin-top: 0px;
  border-top: 6px #32864e solid;
}
.border-hover:hover {
  margin-top: 0px;
  border-top: 5px #32864e solid;
}
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #a7a5a5; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid black; /* creates padding around scroll thumb */
}

select option {
  padding: 3px;
  margin: 3px;
}
.grayscale {
  filter: grayscale(100%);
}
.h-full-minus {
  height: calc(100vh - 120px);
}
.surround-parenthesis::before {
  content: "(";
}
.surround-parenthesis::after {
  content: ")";
}
.surround-parenthesis:empty::before {
  content: "";
}
.surround-parenthesis:empty::after {
  content: "";
}
h2 {
  font-size: 1.5rem;
  line-height: 3rem;
}
.admin h1 {
  font-size: 2rem;
}
.admin {
  margin-top: 6rem;
}
h3,
h4 {
  font-size: 1.25rem;
  line-height: 2rem;
}
.active {
  font-weight: bold;
}
.surround-parenthesis::before {
  content: "(";
}
.surround-parenthesis::after {
  content: ")";
}
.surround-parenthesis:empty::before {
  content: "";
}
.surround-parenthesis:empty::after {
  content: "";
}
.max-h-20 {
  max-height: 5rem;
}
.max-h-24 {
  max-height: 6rem;
}
:focus {
  outline: none;
}

.min-h-64 {
  min-height: 10rem !important;
}
.bg-black\/50 {
  background-color: rgb(0 0 0 / 0.5);
}
.button-icon {
  width: 42px;
  min-width: 42px;
  min-height: 42px;
  height: 42px;
  display: flex;
  justify-items: center;
  align-items: center;
}
.button-icon .icon {
  width: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  height: 1.5rem;
}
.button-icon .icon-padded {
  width: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  height: 2rem;
}
.popuptinymce h3 {
  top: 1rem;
  left: 1rem;
  font-size: var(--font-size-800);
  line-height: 2rem;
  font-weight: 700;
}
.tox .tox-toolbar__primary {
  max-height: 38px;
}
.min-h-screen12 {
  min-height: 50vh;
}
.z-10000 {
  z-index: 10000;
}
.admin input[type="text"],
.admin input[type="search"],
.admin input[type="date"],
.admin input[type="datetime-local"] {
  background-color: #101010;
  border-radius: 4px;
  color: #e0e0e0;
}

.admin input[type="datetime-local"]::-webkit-calendar-picker-indicator,
.admin input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  color: #e0e0e0;
}
.w-36vh {
  width: 36.65vh;
}
.h-36vh {
  height: 36.65vh;
}
