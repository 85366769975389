/* Crew Carousel */

.crew-section {
  --crew-card-size: 20rem;

  padding: 2rem 0;
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
  position: relative;
}
.crew-section h1 {
  grid-column: c / o;
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: bold;
  margin-bottom: var(--space-2);
}
.crew-carousel {
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
  position: relative;
  overflow-x: auto;
}
.crew-carousel h1 {
  position: fixed;
  top: 0;
  left: 60px;
}
.crew-carousel .crew {
  grid-column: d / n;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, var(--crew-card-size));
  color: #444;
  grid-auto-flow: column;
  grid-row: 1;
}
.crew-carousel .crew.program-list {
  grid-gap: 35px 20px;
}
.crew-carousel .crew-member {
  height: var(--crew-card-size);
  width: var(--crew-card-size);
  position: relative;
  aspect-ratio: 1 / 1;
  transition: all 0.5s ease;

  clip-path: polygon(
    0% 35%,
    28% 0%,
    72% 0,
    100% 35%,
    100% 100%,
    50% 100%,
    0% 100%
  );
}

.crew-carousel .crew-member.program-list {
  clip-path: none;
}

.crew-carousel .crew-member:nth-child(even) {
  grid-row: 3;
  position: relative;
  transform: translate(50%, -10%);
  z-index: 1;
}

.crew-carousel .crew-member h3.dymo {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  text-align: center;
  font-size: var(--font-size-600);
}
.crew-carousel .crew-member.program-list h3.dymo {
  bottom: -1rem;
}

.crew-carousel .portrait {
  clip-path: polygon(
    0% 35%,
    28% 0%,
    72% 0,
    100% 35%,
    90% 78%,
    50% 98%,
    10% 78%
  );
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: cover;
}

.crew-carousel .portrait.program-list {
  clip-path: none;
}

/* mobile styling */
@media only screen and (max-width: 480px) {
  .crew-section {
    --crew-card-size: 15rem;
  }
}

/* tablet styling */
@media only screen and (min-width: 481px) and (max-width: 768px) {
}

/* desktop styling */
@media only screen and (min-width: 768.1px) {
}
