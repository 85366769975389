.player {
  grid-column: 1 / -1;
  background: white;
  border-top: 1px solid black;
  padding: 1rem 0;
  color: black;
  z-index: 100;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}
.player .player-panel {
  grid-column: b / p;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
}

.player .player-info {
  flex: 1;
  order: 0;
  align-self: stretch;
  flex-basis: 1;
  flex-grow: 1;
}
.player .player-buttons {
  padding-top: 0.5rem;
  display: flex;
}
.player .player-expand {
  padding-top: 0.5rem;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-basis: 1;
  flex-shrink: 0;

  justify-content: flex-end;
  align-items: flex-start;
}
.player .media-title {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
}

.player .blurb {
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
}

.player .media-play-position {
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
}

@media only screen and (max-width: 480px) {
  .player .player-expand {
    flex-grow: 0;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .player .player-expand {
    flex-grow: 0;
  }
}
