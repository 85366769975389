/* 

  CREWMEMBER PAGE

*/
/* Layout-independent styling of components */

.program-list-compact {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.program-list-compact .li {
  display: flex;
  flex-direction: column;
}
.program-list-compact .li a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  padding-right: 0.75rem;
  background: black;
  color: var(--color-5);
  font-weight: 800;

  transition: all 0.25s ease-in;

  gap: 0.75rem;
}
.program-list-compact .li a:hover {
  background: var(--color-5);
  color: white;
}
.program-list-compact .li a .img {
  flex-grow: 0;
  display: block;
}

.crewmember-image .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: rotate(-12deg);
}

.crewmember-info {
  font-size: 1.25rem;
}
.crewmember-info > * + * {
  margin-top: 0.5rem;
}

.crewmember-programs > h2 {
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  font-weight: bold;
  text-align: right;
  font-size: var(--font-size-800);
}

.crewmember-title h1 {
  text-align: right;
}
.crewmember-info {
  padding-top: 4rem;
}

.crewmember-episode-section {
  z-index: 40;
  position: relative;
}

/* Layouts */

.crewmember-page {
  padding-top: 6em;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-auto-rows: minmax(min-content, max-content);
  grid-column-gap: var(--grid-gap);
}

@media only screen and (max-width: 480px) {
  .crewmember-title {
    grid-column: b / o;
    grid-row: 1 / 1;
    z-index: 2;
  }

  .crewmember-image {
    grid-column: b / p;
    grid-row: 1 / 5;
    aspect-ratio: 1 / 1;
  }

  .crewmember-info {
    grid-column: b / p;
    grid-row: 4 / 4;
    z-index: 3;
    padding-top: 192px;
  }

  .crewmember-programs {
    grid-column: b / p;
    grid-row: 5 / 5;
    z-index: 3;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .crewmember-title {
    grid-column: c / o;
    grid-row: 1 / 1;
    z-index: 2;
  }

  .crewmember-image {
    grid-column: b / o;
    grid-row: 1 / 5;
    aspect-ratio: 1 / 1;
  }

  .crewmember-info {
    grid-column: c / o;
    grid-row: 5 / 5;
    margin-top: -4rem;
    z-index: 3;
  }
  .crewmember-programs {
    grid-column: c / o;
    grid-row: 6 / span 8;
    z-index: 3;
  }
}
@media only screen and (min-width: 769px) {
  .crewmember-title {
    grid-column: c / o;
    grid-row: 1 / 1;
    z-index: 2;
  }

  .crewmember-image {
    grid-column: b / j;
    grid-row: 1 / 5;
    aspect-ratio: 1 / 1;
  }

  .crewmember-info {
    grid-column: i / o;
    grid-row: 2 / 2;
    z-index: 3;
  }
  .crewmember-programs {
    grid-column: h / o;
    grid-row: 3 / span 8;
    z-index: 3;
  }
}
