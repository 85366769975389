.adyen-checkout__button--pay {
  background-color: var(--color-3d-button-bg) !important;
  border: 1px solid var(--color-3d-button-border);
  box-shadow: 0 7px 0 0 var(--color-3d-button-border);
  transform: translateY(-8px);
  border: 1px solid var(--color-button-border);
  font-weight: 800;
  font-size: var(--font-size-600);
  line-height: var(--line-height-600);
  color: var(--color-button-text);
  padding: 0.5rem 0.75rem;
  display: inline-block;
  display: inline-flexbox;
  transition: all 0.125s;
  align-items: stretch;
  align-content: stretch;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0;
}
.adyen-checkout__button--pay img {
  display: none;
}
