/* 

  PROGRAM PAGE

*/
/* Layout-independent styling of components */

.program-actions {
  display: flex;
  padding: 1.5rem 0;
  justify-content: space-between;
}

.program-image .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: rotate(-12deg);
}

.program-info {
  font-size: 1.25rem;
}
.program-info > * + * {
  margin-top: 0.5rem;
}

.program-addtional-info {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.program-addtional-info > * + * {
  margin-top: 0.5rem;
}

/* Program Episode List */

.program-episodes .program-episodes-header {
  padding: var(--space-1);
  box-shadow: inset 0 -1px #00000080;
  display: flex;
  gap: var(--space-05);
  align-items: center;
  justify-content: space-between;
}
.program-episodes .program-episodes-header h2 {
  font-weight: bold;
  font-size: var(--font-step-1);
  line-height: var(--space-2);
}
.program-episodes .program-episodes-header .program-episodes-invitation {
  font-size: var(--font-step--2);
  line-height: var(--space-rem);
}
.program-episodes .program-episodes-header .episode-filter {
  max-width: 33%;
}
.program-episode {
  /*  display: flex;
  align-items: flex-start;*/
  gap: 0.75rem;
  position: relative;
  background: hsl(192, 90%, 20%, 1);
}

.program-episode:hover {
  background: hsl(192, 90%, 33%, 1);
}

.program-episode-actions {
  float: right;
  padding-top: 1rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  display: flex;
}
.program-episode .details {
  box-shadow: inset 0 -1px #00000080;
}
.program-episode .details-open {
  background: hsl(192, 90%, 33%, 1);
}

.program-episode .summary {
  list-style: none;
  cursor: pointer;
  font-weight: bold;
  padding: 0.75rem;
  min-height: 4.25rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  line-height: 1rem;
}
.program-episode .summary .img {
  flex: 0 0 0;
  max-width: 4.5rem;
  /*
  aspect-ratio: 1/ 1;
  object-fit: contain;
*/
}
.program-episode-description {
  padding: 0 0.75rem 0.75rem 0.75rem;
}
.program-episode-description p + p {
  margin-top: 0.5rem;
}

.program-episode-description {
  line-height: 1rem;
  font-size: 0.875rem;
}

.program-episode-description .button--buy {
  display: none;
}

.program-episodes {
  background: hsl(192, 90%, 20%, 1);
}

.program-addtional-info {
  background: #000000c0;
  padding: 1.5rem;
}
.program-addtional-info h3 {
  font-weight: 800;
  font-size: var(--font-size-800);
  line-height: 2.25rem;
}

.program-crew {
  list-style: none;
}

.program-crew li {
  display: flex;
  margin-left: -1.5rem;
}

.program-crew .crew-member {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0.75rem 0.5rem 1.25rem;
  transition: all 0.25s ease-in;
}
.program-crew .crew-member:hover {
  background: var(--color-4);
}
.program-crew .crew-member:hover span {
  box-shadow: 0 1px 0 currentColor;
}
.program-crew .portrait {
  flex-grow: 0;
  flex-shrink: 1;
  clip-path: polygon(
    0% 35%,
    28% 0%,
    72% 0,
    100% 35%,
    90% 78%,
    50% 98%,
    10% 78%
  );
  width: 2.25rem;
  height: 2.25rem;
}

.program-crew .portrait .img {
  width: 100%;
}
.program-crew span {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}

/* Layouts */

.program-page {
  padding-top: 6em;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-auto-rows: minmax(min-content, max-content);
  grid-column-gap: var(--grid-gap);
}

@media only screen and (max-width: 480px) {
  .program-title {
    grid-column: b / p;
    grid-row: 1 / 1;
    z-index: 2;
  }

  .program-episode-actions .button--buy-- {
    display: none;
  }
  .program-image {
    grid-column: b / p;
    grid-row: 1 / 5;
    aspect-ratio: 1 / 1;
  }

  .program-info {
    grid-column: b / p;
    grid-row: 2 / 2;
    z-index: 3;
    padding-top: 144px;
  }

  .program-episode-description .button--buy {
    float: right;
    margin-top: 0.25rem;
    display: block;
  }

  .program-actions {
    grid-column: b / p;
    grid-row: 3 / 3;
  }

  .program-episodes {
    grid-column: b / p;
    grid-row: 4 / 4;
    z-index: 3;
  }

  .program-addtional-info {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .program-title {
    grid-column: c / o;
    grid-row: 1 / 2;
    z-index: 2;
  }

  .program-image {
    grid-column: b / o;
    grid-row: 1 / 5;
    aspect-ratio: 1 / 1;
  }

  .program-info {
    grid-column: c / o;
    grid-row: 3 / 3;
    z-index: 3;
    padding-top: 72px;
  }
  .program-actions {
    grid-column: c / o;
    grid-row: 4 / 4;
  }

  .program-actions-mobile * {
    display: none;
  }
  .program-episodes {
    grid-column: c / m;
    grid-row: 6 / span 8;
    z-index: 3;
  }

  .program-addtional-info {
    grid-column: d / n;
    grid-row: 5 / 5;
    padding: 1.5rem;
    z-index: 3;
  }
}
@media only screen and (min-width: 769px) {
  .program-title {
    grid-column: c / h;
    grid-row: 1 / 2;
    z-index: 2;
  }

  .program-image {
    grid-column: b / j;
    grid-row: 1 / 5;
    aspect-ratio: 1 / 1;
  }

  .program-info {
    grid-column: i / o;
    grid-row: 1 / 1;
    z-index: 3;
    padding-top: 72px;
  }
  .program-actions {
    grid-column: i / o;
    grid-row: 2 / 2;
  }

  .program-actions-mobile * {
    display: none;
  }
  .program-episodes {
    grid-column: i / o;
    grid-row: 3 / span 8;
    z-index: 3;
  }

  .program-addtional-info {
    grid-column: c / i;
    grid-row: 4 / 7;
    z-index: 3;
  }
}
