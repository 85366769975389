/*
These could be reused!

*/

/* slider */

.slider {
  display: flex;
  position: relative;
  background: none;
  height: var(--space-033);
  height: 4px;
  align-items: center;
  justify-items: stretch;
}
.slider .slider-track {
  flex-grow: 1;
  display: block;
  height: var(--space-033);
  background: #00000040;
  position: relative;
}
.slider .slider-track .buffer {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #000;
}

.slider .slider-track .progress {
  position: absolute;
  top: 0;
  bottom: 0;
  background: var(--color-6);
}

.slider .slider-track .handle {
  position: absolute;
  top: 50%;
  height: 48px;
  width: 48px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.slider .slider-track .handle .dot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: var(--color-6);
  transition: all 0.25s ease-in-out;
}

.slider .slider-track .handle:hover .dot {
  height: 1.5rem;
  width: 1.5rem;
}

/* // slider */

.mediaplayer--compact {
  grid-column: 1 / -1;
  background: white;
  border-top: 1px solid black;
  color: black;
  z-index: 10000;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
  grid-template-rows: auto;
}

.mediaplayer--compact .slider {
  grid-column: 1 / -1;
  grid-row: 1 / span 1;
  margin-bottom: 12px;
}

.mediaplayer--compact .player-panel {
  padding: 0 0 1rem 0;
  grid-column: b / p;
  grid-row: 1 / span 1;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: var(--space-base);
}

/* BEGIN media-panel-left */
.mediaplayer--compact .media-panel-left {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  align-self: start;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--space-base);
}

.mediaplayer--compact .media-title {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
  align-self: start;
  grid-column: 2;
  grid-row: 1;
}

.mediaplayer--compact .blurb {
  grid-column: 2;
  grid-row: 2 / span 1;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0 0 0 0rem;
  align-self: start;
}

.mediaplayer--compact .media-thumbnail {
  grid-column: 1 / span 1;
  grid-row: 1 / span 4;
  width: 4.5rem;
  display: block;
  align-self: start;
}

/* END OF media-panel-left */

/* BEGIN media-panel-right */

.mediaplayer--compact .media-panel-right {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  align-self: start;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: var(--space-base);
}

.mediaplayer--compact .media-time {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  align-self: start;
  font-size: 0.875rem;
  line-height: 1rem;
  font-family: "Roboto";
}
.mediaplayer--compact .media-timeposition {
  grid-column: 1;
  grid-row: 1;
  justify-self: start;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1rem;
  align-self: start;
  font-family: "Roboto";
}
.mediaplayer--compact .media-timeduration {
  grid-column: 1;
  grid-row: 1;
  text-align: right;
  font-size: 0.875rem;
  line-height: 1rem;
  justify-self: end;
  align-self: start;
  font-family: "Roboto";
}
.mediaplayer--compact .player-expand {
  grid-column: 2 / span 1;
  grid-row: 1;
  justify-self: end;
  align-self: start;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-basis: 1;
  flex-shrink: 0;

  justify-content: flex-end;
  align-items: flex-start;
}
/* END OF media-panel-right */
/* buttons */
.mediaplayer--compact .player-buttons {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  justify-self: center;
  align-self: start;
  padding-top: 0.5rem;
  display: flex;
}

.mediaplayer--compact .player-buy-button {
  grid-column: 10 / span 4;
  grid-row: 2 / span 4;
  /*  justify-self: center;*/
  padding-top: 0.5rem;
  align-self: start;
  justify-content: end;
  /*  display: flex;
*/
}

@media only screen and (max-width: 480px) {
  .mediaplayer--compact .player-panel {
    grid-column: 2 / -2;

    grid-template-columns: 1fr auto auto;
  }
  .mediaplayer--compact .media-panel-right,
  .mediaplayer--compact .media-thumbnail {
    display: none;
  }
  .mediaplayer--compact .media-title {
    grid-column: 1 / -1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mediaplayer--compact .blurb {
    grid-column: 1 / -1;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mediaplayer--compact .media-panel-right {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    align-self: end;

    display: grid;
    grid-template-columns: auto;
    grid-column-gap: var(--space-base);
  }
  .mediaplayer--compact .media-time,
  .mediaplayer--compact .media-timeposition,
  .mediaplayer--compact .media-timeduration {
    display: none;
  }

  .mediaplayer--compact--compact .player-buttons {
    grid-column: 1 / span 2;
    justify-content: end;
    justify-self: end;
  }
  /* hide some player buttons for mobile in compact mode*/
  .mediaplayer--compact .button--next,
  .mediaplayer--compact .button--previous,
  .mediaplayer--compact .button--backward,
  .mediaplayer--compact .button--forward {
    display: none;
  }

  .mediaplayer--compact .player-expand {
    grid-column: 1;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .mediaplayer--compact .player-panel {
    grid-template-columns: 1fr auto auto;
  }
  .mediaplayer--compact .media-panel-right {
  }

  .mediaplayer--compact .media-time {
    grid-column: 1;
    grid-row: 2;
  }
  .mediaplayer--compact .media-timeposition {
    grid-column: 1;
    grid-row: 1;
    justify-self: start;
    align-self: start;
  }
  .mediaplayer--compact .media-timeduration {
    grid-column: 1;
    grid-row: 3;
    text-align: left;
    justify-self: start;
    align-self: start;
  }
  .mediaplayer--compact .player-expand {
    grid-column: 2 / span 1;
    grid-row: 1 / span 3;
  }

  /* hide some player buttons for tablet in compact mode*/
  .mediaplayer--compact .button--next,
  .mediaplayer--compact .button--previous {
    display: none;
  }
}
