.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}
.header nav {
  grid-column: b / o;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header .home {
  position: absolute;
  top: 0;
  left: 0;

  padding: 0.75rem 0 0.25rem 0;
  background: gold;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  color: black;
}

.header .main-menu {
  display: none;
}

@media only screen and (min-width: 850px) {
  .header .main-menu {
    position: absolute;
    top: 1.5rem;
    right: 0;
    display: flex;
    align-items: flex-end;
    list-style: none;
  }
}
.hamburger {
  background: var(--color-button-bg);
  border: 1px solid var(--color-button-border);
  font-weight: 800;
  font-size: var(--font-size-600);
  line-height: var(--line-height-600);

  color: var(--color-button-text);
  padding: 0.5rem 0.75rem;
  display: block;
  transition: all 0.125s;
  align-items: stretch;
  align-content: stretch;

  background: var(--color-3d-button-bg);
  border: 1px solid var(--color-3d-button-border);
  box-shadow: 0 7px 0 0 var(--color-3d-button-border);

  transform: translateY(-8px);
  display: none;
}

.hamburger > * {
  margin: 0 0.125rem;
}

.hamburger:hover {
  background: var(--color-3d-button-bg-hover);
}

.hamburger:active,
.hamburger.active {
  transform: translateY(-4px);
  box-shadow: 0 2px 0 0 var(--color-3d-button-border);
  transition: all 0s;
}

@media only screen and (max-width: 850px) {
  .header nav {
    grid-column: b / p;
  }
  .hamburger {
    position: absolute;
    top: 1.5rem;
    right: 0;
    display: flex;
  }
  .header .main-menu {
    position: absolute;
    right: 0;
    top: 72px;
    min-width: calc(75vw - 4.5rem);
    max-width: calc(100vw - 4.5rem);
    display: none;
  }
}
