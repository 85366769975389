.featuretext * + * {
  margin-top: var(--space-base);
}
.featuretext .h1 {
  font-size: 2.5625rem;
  line-height: 3rem;
  font-weight: 800;
}

.featuretext h2 {
  font-size: var(--font-size-xl);
  line-height: 3rem;
  font-weight: 800;
}

.featuretext p {
  font-size: var(--font-size-base);
  line-height: var(--leading-base);
  font-weight: normal;
}

.ft--box {
  padding: 1.5rem;
  background: var(--color-background);
  color: var(--color-foreground);
}

.ft--dymo span,
.dymo span {
  display: inline;
  font-weight: inherit;
  padding: 0.0875rem 0.5rem;
  color: var(--color-foreground);
  background: var(--color-background);
  box-sizing: border-box;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  margin: 0;
}
