:root {
  --color-foreground: #000;
  --color-background: #fff;

  --bp-mobile-max: 480px;
  --bp-tablet-min: 481px;
  --bp-tablet-max: 768px;
  --bp-laptop-min: 769px;

  --space-025: 0.25rem;
  --space-05: 0.5rem; /* 8px*/
  --space-base: 0.75rem;
  --space-1: 1rem; /* 16px */
  --space-2: 1.5rem;

  --space-033: 0.25rem; /* 4px */
  --space-066: 0.5rem; /* 8px */
  --space-1: 0.75rem; /* 12px */
  --space-rem: 1rem; /* 16px */
  --space-2: 1.5rem; /* 24px */
  --space-3: 2.25rem; /* 36px */
  --space-4: 3rem; /* 48px */
  --space-6: 4.5rem; /* 72px */
  --space-8: 6rem; /* 96px */

  --clipping-path: polygon(
    16px 16px,
    calc(100% - 8px) 24px,
    calc(100% - 20px) calc(100% - 22px),
    18px calc(100% - 6px)
  );
}

* {
  box-sizing: border-box;
}

.page {
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero-heading {
  font-size: var(--hero-heading-font-size);
  font-weight: 900;
  line-height: var(--hero-heading-line-height);
  text-shadow: var(--hero-heading-shadow);
  color: white;
  margin-bottom: var(--space-2);
}

.hh-white {
  color: white;
}
.hh-color-1 {
  color: var(--color-1);
}
.hh-color-2 {
  color: var(--color-2);
}
.hh-color-3 {
  color: var(--color-3);
}

.hh-color-4 {
  color: var(--color-4);
}

.hh-color-5 {
  color: var(--color-5);
}

.hh-color-6 {
  color: var(--color-6);
}

/* feature sections */

.feature {
  padding: 3rem 0;
  grid-column: 1 / -1;
  background-size: cover;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}

.feature .image {
  aspect-ratio: 1 / 1;
  align-self: center;
}
.feature .image .img {
  display: block;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}
.feature .textbox {
  align-self: center;
}

.feature .image {
  grid-column: b / i;
  grid-row: 1;
}
.feature .textbox {
  grid-column: c / o;
  grid-row: 1;
  z-index: 5;
}

/* feature--center */

.feature--center {
}
.feature--fiftyfifty-text-right .image .img {
  transform: rotate(-5deg);
}

.feature--fiftyfifty-text-left .image .img {
  transform: rotate(5deg);
}

@media only screen and (min-width: 768.1px) {
  .feature--center .image {
    grid-column: b / i;
    grid-row: 1;
  }
  .feature--center .textbox {
    grid-column: e / k;
    grid-row: 1;
    z-index: 5;
  }

  .feature--fiftyfifty-text-right .image {
    grid-column: b / i;
    grid-row: 1;
  }

  .feature--fiftyfifty-text-right .textbox {
    grid-column: i / o;
    grid-row: 1;
  }

  .feature--fiftyfifty-text-left .image {
    grid-column: i / p;
    grid-row: 1;
  }

  .feature--fiftyfifty-text-left .textbox {
    grid-column: c / i;
    grid-row: 1;
  }
}

@media only screen and (max-width: 480px) {
  .feature--fiftyfifty-text-right .image,
  .feature--fiftyfifty-text-left .image,
  .feature--center .image {
    grid-column: b / p;
    grid-row: 1;
    z-index: 2;
    margin-bottom: 1.5rem;
  }

  .feature--center .image {
    display: none;
  }
  .feature--fiftyfifty-text-left .textbox,
  .feature--fiftyfifty-text-right .textbox,
  .feature--center .textbox {
    grid-column: b / p;
    grid-row: 2;
    z-index: 4;
    margin-top: -5rem;
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .feature--fiftyfifty-text-right .image {
    grid-column: b / h;
  }
  .feature--fiftyfifty-text-right .textbox {
    grid-column: h / o;
  }

  .feature--fiftyfifty-text-left .image {
    grid-column: j / p;
  }

  .feature--fiftyfifty-text-left .textbox {
    grid-column: c / j;
  }

  .feature--center .image {
    grid-column: c / o;
    grid-row: 1;
    display: none;
  }
  .feature--center .textbox {
    grid-column: c / m;
    grid-row: 1;
    z-index: 5;
  }
}

.skew-segment {
  padding: 72px 0;
  clip-path: polygon(0% 0%, 100% 2.5vw, 100% 100%, 0% calc(100% - 2.5vw));
  overflow: hidden;
  margin-top: -3rem;
}

.skew-segment-2 {
  padding: 72px 0;
  clip-path: polygon(0% 2.5vw, 100% 0, 100% calc(100% - 2.5vw), 0% 100%);
  overflow: hidden;
}

.crew-section {
  padding: 3rem 0;
  grid-column: 1 / -1;
  background-size: cover;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}

.spacer-0 {
  height: 0;
}

.spacer-3 {
  height: 2.25rem;
}

.spacer-4 {
  height: 3rem;
}

.spacer-5 {
  height: 3rem;
}

/*
PROTOTYPE SELECTOR
*/

.prototype-selector {
  display: grid;
  margin: 2.25rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-auto-rows: minmax(150px, auto);
  gap: 2.25rem;
}

.prototype-selector .prototype-card {
  padding: 1.5rem;
  flex-direction: column;
  transition: all 0.5s ease-out;
  border: 2px solid var(--color-4);
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.prototype-selector .prototype-card > * + * {
  margin-top: 0.75rem;
}
.prototype-selector h2 {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.5rem;
}
.prototype-selector a {
  color: var(--color-4);
  display: block;
  cursor: pointer;
}

.prototype-selector .prototype-card:hover {
  background: var(--color-4);
}

.prototype-selector .prototype-card:hover a {
  color: #fff;
  cursor: pointer;
}

.prototype-selector .prototype-card:hover a:hover {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

/* .hide-mobile */

@media only screen and (max-width: 480px) {
  .hide-mobile {
    display: none !important;
    outline: 12px solid gold;
  }
}
.dev-logo-css {
  position: absolute;
  right: 3px;
  background-color: red;
  padding: 5px 10px;
  border-radius: 60%;
  opacity: 80%;
  rotate: 15deg;
  top: 3px;
  color: white;
  border: 1px white solid;
}
