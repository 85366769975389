/*
These could be reused!

*/

/*VIDEO */

.videocontainer {
  /*  padding: 56.25% 0 0 0;*/
  aspect-ratio: 16 /9;
  position: relative;
  background: red;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mediaplayer--expanded {
  grid-column: 1 / -1;
  background: white;
  border-top: 1px solid black;
  color: black;
  z-index: 10000;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}

.mediaplayer--expanded .player-panel {
  max-height: 82vh;
  padding: 0 0 1rem 0;
  grid-column: b / p;
  grid-row: 1 / span 1;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--grid-gap);
  grid-template-rows: auto minmax(1px, 1fr) auto auto auto;
}

.mediaplayer--expanded .program-title {
  grid-column: 1 / -1;
  grid-row: 1 / span 1;
  display: block;
  color: var(--color-5-dark);
  padding-block: var(--space-base);
  display: inline-block;
  font-size: var(--font-step-0);
  line-height: var(--space-2);
  font-weight: 700;
}

.mediaplayer--expanded .media-contents {
  grid-column: 1 / -1;
  grid-row: 2;

  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--grid-gap);

  overflow-y: scroll;
}

.mediaplayer--expanded .media-title {
  font-weight: bold;
  font-size: var(--font-step-1);
  padding-bottom: var(--space-base);
  line-height: var(--space-2);
  margin: 0;
  align-self: start;
  grid-column: 1 / -1;
  grid-row: 1;
}

.mediaplayer--expanded .media-visuals {
  grid-column: 1 / span 1;
  grid-row: 2;
  display: block;
  align-self: start;
}
.mediaplayer--expanded .media-visuals img {
  display: block;
  width: 100%;
}

.mediaplayer--expanded .media-description {
  grid-column: 2;
  grid-row: 2;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0 0 0 0rem;
  overflow-wrap: break-word;
}

.mediaplayer--expanded .slider {
  grid-column: 1 / -1;
  grid-row: 4;
  margin-block: var(--space-2);
  z-index: 3;
}

.mediaplayer--expanded .media-timeposition {
  grid-column: 1;
  grid-row: 4;
  justify-self: start;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1rem;
  align-self: end;
  font-family: "Roboto";
  padding-bottom: var(--space-033);
}
.mediaplayer--expanded .media-timeduration {
  grid-column: 2;
  grid-row: 4;
  text-align: right;
  font-size: 0.875rem;
  line-height: 1rem;
  justify-self: end;
  align-self: end;
  font-family: "Roboto";
}

/* buttons */
.mediaplayer--expanded .player-buttons {
  grid-column: 1 / -1;
  grid-row: 5;
  justify-self: center;
  align-self: start;
  padding-top: 0.5rem;
  display: flex;
}

.mediaplayer--expanded .player-close {
  grid-column: 2;
  grid-row: 1;
  justify-self: end;
  align-self: end;

  flex: none;
  flex-grow: 1;
  display: flex;
  flex-basis: 1;
  flex-shrink: 0;

  justify-content: flex-end;
  align-items: flex-start;
  z-index: 100;
}

.mediaplayer--expanded .player-expand {
  grid-column: 2;
  grid-row: 5;
  justify-self: end;
  align-self: end;

  flex: none;
  order: 2;
  flex-grow: 1;
  display: flex;
  flex-basis: 1;
  flex-shrink: 0;

  justify-content: flex-end;
  align-items: flex-start;
}

/* BEGIN media-panel-left */
.mediaplayer--expanded .media-panel-left {
  display: none;
}

/* END OF media-panel-left */

/* BEGIN media-panel-right */

.mediaplayer--expanded .media-panel-right {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  align-self: start;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: var(--space-base);
  display: none;
}

.mediaplayer--expanded .media-time {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  align-self: start;
  font-size: 0.875rem;
  line-height: 1rem;
  font-family: "Roboto";
}
/* END OF media-panel-right */

.mediaplayer--expanded .player-buy-button {
  grid-column: 10 / span 4;
  grid-row: 2 / span 4;
  /*  justify-self: center;*/
  padding-top: 0.5rem;
  align-self: start;
  justify-content: end;
  /*  display: flex;
*/
}

@media only screen and (max-width: 480px) {
  .mediaplayer--expanded .player-panel {
    grid-column: 2 / -2;
  }
  .mediaplayer--expanded .media-title {
    grid-column: 1 / -1;
    grid-row: 1;
    padding-bottom: var(--space-base);
  }

  .mediaplayer--expanded .media-visuals {
    grid-column: 1 / -1;
    grid-row: 3;
  }
  .mediaplayer--expanded .media-description {
    grid-column: 1 / -1;
    grid-row: 2;
    padding-block: var(--space-base);
  }
  .mediaplayer--expanded.mediaplayer--video .media-visuals {
    grid-row: 2;
  }
  .mediaplayer--expanded.mediaplayer--video .media-description {
    grid-row: 3;
  }
  .mediaplayer--expanded .player-buttons {
    grid-column: 1 / -1;
    justify-self: start;
    align-self: start;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .mediaplayer--expanded .player-panel {
  }
  .mediaplayer--expanded .media-panel-right {
  }
}
