.start-logged-in {
  padding-top: var(--space-6);
  --color-concentric-1: var(--color-2-darker);
  --color-concentric-2: var(--color-2-darkest);

  background: radial-gradient(
    circle,
    var(--color-concentric-1) 0%,
    var(--color-concentric-1) 5%,
    var(--color-concentric-2) 5.01%,
    var(--color-concentric-2) 10%,
    var(--color-concentric-1) 10.01%,
    var(--color-concentric-1) 15%,
    var(--color-concentric-2) 15.01%,
    var(--color-concentric-2) 20%,
    var(--color-concentric-1) 20.01%,

    var(--color-concentric-1) 25%,
    var(--color-concentric-2) 25.01%,
    var(--color-concentric-2) 30%,
    var(--color-concentric-1) 30.01%,

    var(--color-concentric-1) 35%,
    var(--color-concentric-2) 35.01%,

    var(--color-concentric-2) 40%,
    var(--color-concentric-1) 40.01%,

    var(--color-concentric-1) 45%,

    var(--color-concentric-2) 45.01%,
    var(--color-concentric-2) 50%,
    var(--color-concentric-1) 50.01%,
    var(--color-concentric-1) 55%,

    var(--color-concentric-2) 55.01%,
    var(--color-concentric-2) 60%,
    var(--color-concentric-1) 60.01%,
    var(--color-concentric-1) 65%,

    var(--color-concentric-2) 65.01%,
    var(--color-concentric-2) 70%,
    var(--color-concentric-1) 70.01%,
    var(--color-concentric-1) 75%,

    var(--color-concentric-2) 75.01%,
    var(--color-concentric-2) 80%,
    var(--color-concentric-1) 80.01%,
    var(--color-concentric-1) 85%,

    var(--color-concentric-2) 85.01%,
    var(--color-concentric-2) 90%,
    var(--color-concentric-1) 90.01%,
    var(--color-concentric-1) 95%,

    var(--color-concentric-2) 95.01%,

    var(--color-concentric-2) 100%
  );
  background-attachment: fixed;
}

.greeting {
  padding: 6rem 0 0 0;
  grid-column: 1 / -1;
  background-size: cover;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}
.greeting h1 {
  grid-column: c / o;
}
.greeting .greeting-text {
  grid-column: c / o;
}
.greeting .greeting-text > * + * {
  margin-top: var(--space-base);
}

.promotional-carousel {
  padding-top: var(--space-2);
  padding-inline: 0;
  padding-bottom: var(--space-4);
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}
.carousel-slides {
  grid-column: b / p;
  aspect-ratio: 2 / 1;
  background: #00000080;
  background: none;
  color: white;
  position: relative;
  overflow: hidden;
}
/*

   33%     30s
 V - - V - - - 
   V - - 
     V - - 


   20%     50s
 V - - - - V - - - 
   V - - - - 
     V - - - - 
       V - - - - 
         V V - - - 

 */
.carousel-slide {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: -1;
  background: #00000040;
}
/* This is how the carousel-slide wants to use the .contentobject */

.carousel-slide .contentobject {
  position: absolute;
  inset: 0;
}
.carousel-slide.carousel-enter {
  animation: 2s carouselling-enter 1 normal forwards ease-in-out;
  z-index: 1;
}
.carousel-slide.carousel-show {
  clip-path: none;
  opacity: 1;
  z-index: 1;
}
.carousel-slide.carousel-exit {
  animation: 2s carouselling-exit 1 normal forwards ease-in-out;
  z-index: 1;
}

:root {
  --carouselclip-show1: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
  --carouselclip-show2: polygon(90% 0%, 100% 0%, 100% 100%, 75% 100%);
  --carouselclip-show3: polygon(90% 0%, 100% 0%, 100% 100%, 0% 100%);

  --carouselclip-full: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

  --carouselclip-hide1: polygon(0% 0%, 25% 0%, 0% 100%, 0% 100%);
  --carouselclip-hidden: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
}
/*
.carousel-slide-1 {
  background: gold;
  opacity: 1;
  animation-delay: 0ms;
}
.carousel-slide-2 {
  background: orange;
  animation-delay: 10000ms;
}
.carousel-slide-3 {
  background: #444;
  animation-delay: 20000ms;
}
.carousel-slide-4 {
  background: #0cf;
  animation-delay: 30000ms;
}

.carousel-slide-5 {
  background: #cf0;
  animation-delay: 40000ms;
}
.carousel-with-1 .carousel-slide {
  animation: none;
  opacity: 1;
}

.carousel-with-2 .carousel-slide {
  animation-name: carouselling-2;
  animation-duration: 20s;
}

.carousel-with-3 .carousel-slide {
  animation-name: carouselling-3;
  animation-duration: 30s;
}

.carousel-with-4 .carousel-slide {
  animation-name: carouselling-4;
  animation-duration: 40s;
}

.carousel-with-5 .carousel-slide {
  animation-name: carouselling-5;
  animation-duration: 50s;
}
*/
@keyframes carouselling-enter {
  0% {
    opacity: 1;
    clip-path: var(--carouselclip-show1);
  }
  33% {
    clip-path: var(--carouselclip-show2);
  }

  66% {
    clip-path: var(--carouselclip-show3);
  }

  100% {
    clip-path: var(--carouselclip-full);
    opacity: 1;
  }
}
@keyframes carouselling-exit {
  0% {
    opacity: 1;
    clip-path: var(--carouselclip-full);
  }
  66% {
    clip-path: var(--carouselclip-hide1);
  }
  98% {
    clip-path: var(--carouselclip-hidden);
    opacity: 1;
  }
  100% {
    opacity: 0;
    clip-path: var(--carouselclip-hidden);
  }
}
@keyframes carouselling-2 {
  0% {
    opacity: 1;
    clip-path: var(--carouselclip-show1);
  }
  2.5% {
    clip-path: var(--carouselclip-show2);
  }

  5% {
    clip-path: var(--carouselclip-show3);
  }

  7.5% {
    clip-path: var(--carouselclip-full);
  }

  48.75% {
    clip-path: var(--carouselclip-full);
  }
  50% {
    clip-path: var(--carouselclip-hide1);
    opacity: 1;
  }
  51.25% {
    clip-path: var(--carouselclip-hidden);
    opacity: 1;
  }
  100% {
    clip-path: var(--carouselclip-hidden);
    opacity: 0;
  }
}

@keyframes carouselling-3 {
  0% {
    opacity: 1;
    clip-path: var(--carouselclip-show1);
  }
  1.67% {
    clip-path: var(--carouselclip-show2);
  }

  3.33% {
    clip-path: var(--carouselclip-show3);
  }

  5% {
    clip-path: var(--carouselclip-full);
  }

  32.5% {
    clip-path: var(--carouselclip-full);
  }
  33.33% {
    clip-path: var(--carouselclip-hide1);
    opacity: 1;
  }
  34.17% {
    clip-path: var(--carouselclip-hidden);
    opacity: 1;
  }
  100% {
    clip-path: var(--carouselclip-hidden);
    opacity: 0;
  }
}
@keyframes carouselling-4 {
  0% {
    opacity: 1;
    clip-path: var(--carouselclip-show1);
  }
  1.25% {
    clip-path: var(--carouselclip-show2);
  }

  2.5% {
    clip-path: var(--carouselclip-show3);
  }

  3.75% {
    clip-path: var(--carouselclip-full);
  }

  24.38% {
    clip-path: var(--carouselclip-full);
  }
  25% {
    clip-path: var(--carouselclip-hide1);
    opacity: 1;
  }
  25.63% {
    clip-path: var(--carouselclip-hidden);
    opacity: 1;
  }
  100% {
    clip-path: var(--carouselclip-hidden);
    opacity: 0;
  }
}
@keyframes carouselling-5 {
  0% {
    opacity: 1;
    clip-path: var(--carouselclip-show1);
  }
  1% {
    clip-path: var(--carouselclip-show2);
  }

  2% {
    clip-path: var(--carouselclip-show3);
  }

  3% {
    clip-path: var(--carouselclip-full);
  }

  19.5% {
    clip-path: var(--carouselclip-full);
  }
  20% {
    clip-path: var(--carouselclip-hide1);
    opacity: 1;
  }
  20.5% {
    clip-path: var(--carouselclip-hidden);
    opacity: 1;
  }
  100% {
    clip-path: var(--carouselclip-hidden);
    opacity: 0;
  }
}

@media only screen and (max-width: 480px) {
  .carousel-slides {
    aspect-ratio: 2 / 3;
  }
}
@media only screen and (min-width: 481px) and (max-width: 800px) {
  .carousel-slides {
    aspect-ratio: 1 / 1;
  }
}
