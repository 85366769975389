:root {
  --font-step--2: 0.75rem; /* 12px */
  --font-step--1: 1rem; /* 16px */
  --font-step-0: 1.25rem; /* 18px */
  --font-step-1: 1.5rem; /* 24px */
  --font-step-2: 1.875rem; /* 30px */
  --font-step-3: 2.25rem; /* 36px */
  --font-step-4: 2.8125rem; /* 45px */
  --font-step-5: 3.375rem; /* 54px */
  --font-step-6: 4.5rem; /* 72px */

  --line-height-200: 0.75rem;
  --line-height-400: 1rem;
  --line-height-500: 1.125rem; /* 18px */
  --line-height-600: 1.25rem; /* 20px */
  --line-height-xxl: 5.25rem; /* 72px*/
  --line-height-600: 1.5rem;

  --font-size-xs: 0.75rem;
  --font-size-sm: 0.75rem; /* 12px*/
  --font-size-base: var(--font-step-0); /* 16 - 18px  */
  --font-size-lg: var(--font-step-1); /* 24px */
  --font-size-xl: var(--font-step-3); /* 30 - 36px */
  --font-size-xxl: var(--font-step-6); /* 36 - 72px*/

  --font-size-400: 1rem;
  --font-size-600: 1.125rem;
  --font-size-700: 1.25rem; /* 20px */
  --font-size-800: 1.5rem;
  --line-height-xl: var(--space-3);
  --line-height-xxl: var(--space-6);
  --leading-xs: 0.75rem;
  --leading-sm: 1rem;
  --leading-base: 1.5rem;
  --leading-lg: 2.25rem; /* 36px */
  --leading-xl: 4rem; /* 48px */

  --color-hero-heading-stroke: #000;
  --hero-heading-stroke-width: 0.0125em;
  --hero-heading-font-size: var(--font-size-xxl);
  --hero-heading-line-height: 3.75rem;

  /* prettier-ignore */
  --hero-heading-shadow:
 
     calc(var(--hero-heading-stroke-width) * -1) calc(var(--hero-heading-stroke-width) * -1) var(--color-hero-heading-stroke), /* 1: -1 -1 */
     calc(var(--hero-heading-stroke-width) * 0) calc(var(--hero-heading-stroke-width) * -1) var(--color-hero-heading-stroke), /* 2:  0 -1 */
     calc(var(--hero-heading-stroke-width) * 1) calc(var(--hero-heading-stroke-width) * -1) var(--color-hero-heading-stroke), /* 3:  1 -1 */

     calc(var(--hero-heading-stroke-width) * -1) calc(var(--hero-heading-stroke-width) * 0) var(--color-hero-heading-stroke), /* 4: -1  0 */
     calc(var(--hero-heading-stroke-width) * 1) calc(var(--hero-heading-stroke-width) * 0) var(--color-hero-heading-stroke), /* 5:  1  0 */
 
     calc(var(--hero-heading-stroke-width) * -1) calc(var(--hero-heading-stroke-width) * 1) var(--color-hero-heading-stroke), /* 6: -1  1 */
     calc(var(--hero-heading-stroke-width) * 0) calc(var(--hero-heading-stroke-width) * 1) var(--color-hero-heading-stroke), /* 7:  0  1 */
     calc(var(--hero-heading-stroke-width) * 1) calc(var(--hero-heading-stroke-width) * 1) var(--color-hero-heading-stroke), /* 8:  1  1 */

     calc(var(--hero-heading-stroke-width) * -1) calc(var(--hero-heading-stroke-width) * 2) var(--color-hero-heading-stroke), /* 1: -1 2 */
     calc(var(--hero-heading-stroke-width) * 0) calc(var(--hero-heading-stroke-width) * 2) var(--color-hero-heading-stroke), /* 2:  0 2 */
     calc(var(--hero-heading-stroke-width) * 1) calc(var(--hero-heading-stroke-width) * 2) var(--color-hero-heading-stroke), /* 3:  1 2 */

     calc(var(--hero-heading-stroke-width) * -1) calc(var(--hero-heading-stroke-width) * 4) var(--color-hero-heading-stroke), /* 1: -1 4 */
     calc(var(--hero-heading-stroke-width) * 0) calc(var(--hero-heading-stroke-width) * 4) var(--color-hero-heading-stroke), /* 2:  0 4 */
     calc(var(--hero-heading-stroke-width) * 1) calc(var(--hero-heading-stroke-width) * 4) var(--color-hero-heading-stroke), /* 3:  1 4 */

     calc(var(--hero-heading-stroke-width) * -1) calc(var(--hero-heading-stroke-width) * 6) var(--color-hero-heading-stroke), /* 1: -1 6 */
     calc(var(--hero-heading-stroke-width) * 0) calc(var(--hero-heading-stroke-width) * 6) var(--color-hero-heading-stroke), /* 2:  0 6 */
     calc(var(--hero-heading-stroke-width) * 1) calc(var(--hero-heading-stroke-width) * 6) var(--color-hero-heading-stroke), /* 3:  1 6 */

     calc(var(--hero-heading-stroke-width) * -1) calc(var(--hero-heading-stroke-width) * 8) var(--color-hero-heading-stroke), /* 1: -1 8 */
     calc(var(--hero-heading-stroke-width) * 0) calc(var(--hero-heading-stroke-width) * 8) var(--color-hero-heading-stroke), /* 2:  0 8 */
     calc(var(--hero-heading-stroke-width) * 1) calc(var(--hero-heading-stroke-width) * 8) var(--color-hero-heading-stroke) /* 3:  1 8 */
     ;
}

body {
  font-family: Gantari, Sans-serif;
}

.eyebrow {
  text-transform: uppercase;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-600);
  font-weight: normal;
}
/*

Typography for editorial surfaces

*/

.editorial .eyebrow {
  line-height: var(--space-base);
  display: block;
}
.editorial h1,
.heading1 {
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: 800;
  margin-bottom: var(--space-base);
}

.editorial h2 {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  font-weight: 800;
  margin-bottom: var(--space-base);
}
.editorial h3 {
  font-size: var(--font-step-2);
  line-height: var(--space-3);
  font-weight: 700;
  margin-bottom: var(--space-base);
}

.editorial h4 {
  font-size: var(--font-size-base);
  line-height: var(--space-2);
  font-weight: 700;
  margin-bottom: var(--space-base);
}
.editorial h5 {
  font-size: var(--font-size-base);
  line-height: var(--space-2);
  font-weight: 400;
  font-style: italic;
  margin-bottom: var(--space-base);
}
.editorial a {
  color: var(--color-link);
  overflow-wrap: break-word;
  text-decoration: underline;
  text-underline-offset: 0.1em;
}
.editorial a:hover,
.editorial a:focus {
  color: var(--color-link-hover);
  text-decoration: underline;
}
.editorial .img {
  max-width: 100%;
  display: block;
  margin-bottom: var(--space-base);
}

.editorial p,
.editorial ul,
.editorial ol,
.editorial dl,
.editorial blockquote {
  font-size: var(--font-size-base);
  line-height: var(--space-2);
  margin-bottom: var(--space-base);
}

.editorial ul {
  list-style: disc;
  padding-left: var(--space-2);
}
.editorial ol {
  list-style-type: decimal;
  padding-left: var(--space-2);
}

.heading2 {
  font-size: var(--font-size-800);
  line-height: var(--line-height-600);
  font-weight: 700;
  margin-bottom: var(--space-base);
}

@media only screen and (max-width: 480px) {
  :root {
    --font-size-base: var(--font-step--1);
    --font-size-xl: var(--font-step-2); /* 30px */
    --line-height-xl: var(--space-3);

    --font-size-xxl: var(--font-step-3); /* 36px */
    --line-height-xxl: var(--space-3); /* 36px */

    --hero-heading-line-height: 2rem;
  }
  .editorial h3 {
    font-size: var(--font-step-0);
    line-height: var(--space-2);
    font-weight: 700;
  }
}
@media only screen and (min-width: 481px) {
  :root {
    --font-size-xxl: clamp(2.25rem, calc(1.47rem + 3.91vw), 4.5rem);
    --hero-heading-line-height: clamp(2rem, calc(1.39rem + 3.04vw), 3.75rem);
  }
}
