/* feature sections */

.signup-section {
  padding: 3rem 0;
  grid-column: 1 / -1;
  background-size: cover;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
  position: relative;
  align-items: center;
}

.signup-section {
  min-height: 75vh;
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
}
.hero-signup {
  display: flex;
  gap: 0.75rem;
  margin-top: 1.5rem;

  flex-wrap: nowrap;
}

.hero-signup .email {
  flex-grow: 1;
}
.hero-signup .email input {
  width: 100%;
  display: block;
}

.hero-signup .button {
  flex-grow: 0;
}

.signup-section .textbox {
  grid-column: b / o;
  grid-row: 1;
  z-index: 5;
  align-self: center;
  background: #00000080;
  padding: var(--space-2);
  display: inline-table;
}

/* feature--center */

.signup-section .signup-section-image-grid {
  position: absolute;
  inset: 0;
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-content: center;
}

.signup-section .signup-section-image-grid-images {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  display: grid;
  position: relative;
  width: 124vw;
  right: -12vw;
  transform: rotate(-10deg);
  grid-template-rows: auto;
  margin: auto;
}
.signup-section .signup-section-image-grid-images .img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

@media only screen and (min-width: 768.1px) {
  .signup-section .textbox {
    grid-column: d / k;
    grid-row: 1;
    z-index: 5;
  }
}

@media only screen and (max-width: 480px) {
  .signup-section .signup-section-image-grid-images {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .feature--center .textbox {
    grid-column: b / p;
    grid-row: 2;
    z-index: 4;
    margin-top: -5rem;
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .signup-section .signup-section-image-grid-images {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
