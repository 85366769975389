:root {
  --grid-gap: 36px;
  --grid-col-line: 0px;

  --grid-col: minmax(1px, 48px); /* was 120px*/
  --grid-col-first: 1fr;
  --grid-col-last: 1fr;

  /* prettier-ignore */
  --grid--receptet: 
    [a] var(--grid-col-first)
    [b] var(--grid-col)
    [c header-start footer-start] var(--grid-col)
    [d meta-end main-start] var(--grid-col)
    [e] var(--grid-col)
    [f] var(--grid-col)
    [g] var(--grid-col)
    [h] var(--grid-col)
    [i] var(--grid-col)
    [j] var(--grid-col)
    [k] var(--grid-col)
    [l] var(--grid-col)
    [m] var(--grid-col)
    [n page-end header-end footer-end] var(--grid-col)
    [o] var(--grid-col)
    [p] var(--grid-col-last);

  /* prettier-ignore */
  --grid--inner: 
  [b] var(--grid-col)
  [c] var(--grid-col)
  [d] var(--grid-col)
  [e] var(--grid-col)
  [f] var(--grid-col)
  [g] var(--grid-col)
  [h] var(--grid-col)
  [i] var(--grid-col)
  [j] var(--grid-col)
  [k] var(--grid-col)
  [l] var(--grid-col)
  [m] var(--grid-col)
  [n] var(--grid-col)
  [o] var(--grid-col)
}

body {
  margin: 0 auto;
  min-height: 101vh;
}

.page {
}

/* OUTER GRID AND PLACEMENT OF ITS CHILDREN */
#root {
  min-height: 100vh;
  color: white;
  max-width: 100vw;
  overflow-x: hidden;
  background: #282030;
  position: relative;
  overflow-y: hidden;
}

.main {
  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
  padding: 0 0 12rem 0;
}

footer {
  background: #00ff0080;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
  z-index: 1000;
}
.dialogs {
  background: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);

  z-index: 2000;
}
/* end of OUTER GRID AND PLACEMENT OF ITS CHILDREN */

/* PERHAPS REMOVE?? */

main > p {
  grid-column: c / h;
  background: #00000080;
}

.span-fw {
  grid-column: a / -1;
  background: #f0000080;
}

.span-main {
  grid-column: c / m;
  background: #00f00080;
}

.span-page {
  grid-column: b / n;
  background: #0000f080;
}

@media only screen and (max-width: 640px) {
  :root {
    --grid-col-first: 24px;
    --grid-col-last: 24px;
    --grid-col: 1fr; /* was 120px*/
    --grid-gap: 0px;
  }
}
