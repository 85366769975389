.slide-promotion {
  --slide-bgcolor: var(--color-7);
  --slide-eyebrow-bgcolor: var(--color-7);
}

.slide-promotion .eyebrow.dymo {
  --color-foreground: white;
  --color-background: black;
}

.slide-promotion {
  position: absolute;
  inset: 0;
  display: grid;
  grid-template-columns: var(--grid--inner);
  grid-column-gap: var(--grid-gap);
  background: var(--slide-bgcolor);
  justify-content: center;
  align-items: center;
  grid-template-rows: 1fr;
}

.slide-promotion .image {
  grid-column: c / h;
  grid-row: 1;
}

.slide-promotion .textbox {
  grid-column: g / o;
  grid-row: 1;
  z-index: 5;
}

.slide-promotion .image img {
  transform: rotate(-5deg);
  width: 100%;
  aspect-ratio: 1 / 1;
  display: block;
  object-fit: cover;
}
.slide-promotion .textbox > * + * {
  margin-top: var(--space-base);
}
.slide-promotion .dymo {
  --color-foreground: white;
  --color-background: var(--color-6);
  max-width: 48ch;
}
.slide-promotion .textbox .button {
  margin-top: var(--space-2);
}

@media only screen and (max-width: 480px) {
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  /*
  .slide-promotion .image {
    grid-column: b / h;
  }
  .slide-promotion .textbox {
    grid-column: f/ p;
    padding-right: 1.25rem;
    box-sizing: border-box;
  }
*/
}
@media only screen and (min-width: 769px) {
}
@media only screen and (max-width: 480px) {
  .slide-promotion .image {
    grid-column: b / -1;
    grid-row: 1 / span 1;
    align-self: start;
  }
  .slide-promotion .image img {
    transform: rotate(0);
  }
  .slide-promotion .textbox {
    grid-column: c / n;
    grid-row: 1 / span 1;
    z-index: 5;
    align-self: end;
    padding-bottom: var(--space-1);
  }
}

@media only screen and (min-width: 481px) and (max-width: 800px) {
  .slide-promotion .image {
    grid-column: c / o;
    grid-row: 1 / span 1;
    align-self: start;
  }
  .slide-promotion .image img {
  }
  .slide-promotion .textbox {
    grid-column: d / n;
    grid-row: 1 / span 1;
    z-index: 5;
    align-self: end;
    padding-bottom: var(--space-1);
  }
}
/* shifted */
