/* 

Form things


*/

/* textfield 3d */

.inset-3d {
  padding: 0.5rem;
  box-shadow: inset 0 0.5rem 0 0 rgba(0, 0, 0, 0.25);
  border: 1px solid black;
  color: black;
  font-size: var(--font-size-base);
  line-height: var(--leading-base);
  font-weight: normal;
}

.inset-3d:focus {
  outline: 0.25rem solid #0cf;
}

.up-form {
  display: block;
  widht: 100%;
}

.up-form-segment {
  display: block;
}

.up-form-segment h3 {
  font-weight: bold;
}
.up-form-segment .required-field {
  padding: 0 0.125rem;
}

.up-form-segment .error-message {
  color: var(--color-error);
}
@media only screen and (min-width: 481px) {
  .inset-3d.mega {
    font-size: 1.6875rem;
    line-height: 2.25rem;
    padding: 0.8rem;
  }
}
