.toggle-3d input {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle-3d {
  --color-3d-button-bg: var(--color-filter);
  --color-3d-button-bg-hover: var(--color-filter-selected);
  background: var(--color-button-bg);
  border: 1px solid var(--color-button-border);
  font-weight: 800;
  font-size: var(--font-size-600);
  line-height: var(--line-height-600);

  color: var(--color-button-text);
  padding: 0.5rem 0.75rem;
  display: inline-block;
  display: inline-flexbox;
  transition: all 0.125s;
  align-items: stretch;
  align-content: stretch;
  white-space: nowrap;

  background: var(--color-3d-button-bg);
  border: 1px solid var(--color-3d-button-border);
  box-shadow: 0 7px 0 0 var(--color-3d-button-border);

  transform: translateY(-8px);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.toggle-3d > * {
  margin: 0 0.125rem;
}
.toggle-3d:hover {
  background: var(--color-button-bg-hover);
  background: var(--color-3d-button-bg-hover);
}

.toggle-3d.active {
  color: white;
  transform: translateY(-4px);
  box-shadow: 0 2px 0 0 var(--color-3d-button-border),
    0 0 1rem 0 var(--color-filter-selected);
  transition: all 0s;
}
