:root {
  /* colors */
  --color-1: hsl(52, 100%, 50%, 1);
  --color-1-bright: hsl(52, 100%, 66%, 1);
  --color-2: hsl(176, 56%, 55%, 1);
  --color-2-bright: hsl(176, 56%, 72%, 1);
  --color-2-dark: hsl(176, 56%, 33%, 1);
  --color-2-darker: hsl(176, 56%, 22%, 1);
  --color-2-darkest: hsl(176, 56%, 18%, 1);
  --color-3: hsl(0, 100%, 55%, 1);
  --color-4: hsl(28, 100%, 53%, 1);
  --color-5: hsl(72, 75%, 50%, 1);
  --color-5-dark: hsl(72, 80%, 33%, 1);
  --color-5-bright: hsl(72, 80%, 66%, 1);
  --color-6: hsl(332, 100%, 45%, 1);
  --color-6-bright: hsl(332, 100%, 66%, 1);
  --color-6-brighter: hsl(332, 100%, 77%, 1);
  --color-7: #054f61; /* USED IN EPIDOSE LIST */
  --color-5-bright: hsl(72, 80%, 66%, 1);

  --color-bg-1: hsl(270, 33%, 19%, 1);
  --color-error: var(--color-3);

  --color-filter: hsl(192, 100%, 50%, 1);
  --color-filter-selected: hsl(192, 100%, 66%, 1);

  --color-link: var(--color-2);
  --color-link-hover: var(--color-2-bright);
}

/* themes */

.theme--bright-on-transparent {
  --color-foreground: #fff;
  --color-background: none;
}

.theme--bright-on-semiopaque {
  --color-foreground: #fff;
  --color-background: #000000a0;
}

.theme--bright-on-dark {
  --color-foreground: #fff;
  --color-background: #000;
}
.theme--bright-on-blue {
  --color-foreground: #fff;
  --color-background: var(--color-2);
}

.theme--bright-on-1 {
  --color-foreground: #fff;
  --color-background: var(--color-1);
}
.theme--bright-on-6 {
  --color-foreground: #fff;
  --color-background: var(--color-6);
  --color-link: #fff;
  --color-link-hover: #fff;
}

.theme--bright-on-2 {
  --color-foreground: #fff;
  --color-background: var(--color-2-dark);
  --color-link: #fff;
  --color-link-hover: #fff;
}

.theme--bright-on-3 {
  --color-foreground: #fff;
  --color-background: var(--color-3);
  --color-link: #fff;
  --color-link-hover: #fff;
}

.theme--bright-on-4 {
  --color-foreground: #fff;
  --color-background: var(--color-4);
}

.theme--dark-on-bright {
  --color-foreground: #000;
  --color-background: #fff;
}
.theme--dark-on-1 {
  --color-foreground: #000;
  --color-background: var(--color-1);
  --color-link: var(--color-2-darker);
  --color-link-hover: var(--color-2-dark);
}

.theme--dark-on-2 {
  --color-foreground: #000;
  --color-background: var(--color-2-bright);
  --color-link: var(--color-2-darker);
  --color-link-hover: var(--color-2-dark);
}

.theme--dark-on-4 {
  --color-foreground: #000;
  --color-background: var(--color-4);
}

.theme--color-4-on-dark {
  --color-foreground: var(--color-4);
  --color-background: #000;
}

.theme--color-6-on-dark {
  --color-foreground: var(--color64);
  --color-background: #000;
}
