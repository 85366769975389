.input-group {
  display: flex;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  flex-wrap: wrap;
}

.input-group h2 {
  flex-basis: 100%;
  padding-bottom: var(--space-2);

  font-weight: 800;
  font-size: var(--font-size-base);
  line-height: var(--line-height-400);
}

.episode-section .views label {
  flex-grow: 1;
  display: flex;
}
.episode-section .views label span {
  flex-grow: 1;
  text-align: center;
}

.episode-section .episodes {
  grid-column: 1 / -1;
  grid-row: 3;
  display: flex;
  column-gap: var(--space-2);
  padding-block: var(--space-2);

  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.episode-section {
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
  padding-top: var(--space-1);
  background: rgb(8, 120, 145, 0);
}
.episode-section > h1,
.episode-section > h2 {
  grid-row: 1;
  grid-column: c / o;
}
.episode-section .filtersection {
  grid-row: 2;
  grid-column: c / o;
  display: flex;
  gap: var(--space-2);

  margin-bottom: var(--space-2);
}
.episode-section .filters {
}
.episode-section .search {
}
.episode-section .search input {
  margin-right: var(--space-base);
  flex-grow: 1;
  min-width: 6rem;
  display: flex;
  flex-basis: 100%;
}

.wrap-group {
  display: flex;
  flex-wrap: wrap;
}

.search-group {
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
}
.search-group input {
}

.radio-toogle-group {
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
}
.radio-toogle-group > * {
  flex-basis: 100%;
  flex-shrink: 1;
}

@media only screen and (max-width: 480px) {
  .episode-section .filtersection {
    flex-direction: column;
    grid-column: b / p;
  }
  .episode-section h1 {
    grid-column: b / p;
  }
  .episode-section .episodes {
    grid-column: b / p;
    flex-direction: column;
    gap: 0;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .episode-section .filtersection {
    flex-direction: column;
  }
}

/* styles above SHOULD HAVE ITS OWN FILE! */

.episodecard {
  background: black;
  color: white;
  max-width: 32ch;
  min-width: 32ch;
  margin-bottom: 2.25rem;
  position: relative;
}

.episodecard .episode-program-title {
  padding-inline: var(--space-1);
  padding-block: var(--space-1);
  color: var(--color-5);
  font-weight: 700;
  display: block;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-underline-offset: 0.125rem;

  min-height: 2.5rem;
  font-size: var(--font-size-400);
  line-height: var(--line-height-400);
}

.episodecard .details .summary {
  padding-inline: var(--space-1);
  padding-bottom: var(--space-2);

  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}
.episodecard .details .summary-texts {
}
.episodecard .details summary:hover .episode-name {
  color: var(--color-4);
}
.episodecard .details .summary::-webkit-details-marker {
  display: none;
}

.episodecard .episode-program-title:hover {
  color: var(--color-5-bright);
}
.episodecard .episode-date {
  line-height: var(--line-height-200);
  font-size: var(--font-size-sm);
  margin-bottom: var(--space-025);
}

.episodecard .episode-name {
  line-height: 1.25rem;
  font-size: var(--font-size-700);
  font-weight: 800;
  margin-bottom: var(--space-05);
}
.episodecard .episode-info {
  padding-inline: var(--space-1);
  padding-bottom: var(--space-2);
  margin-top: calc(var(--space-2) * -1);
}
.episodecard p {
  font-size: var(--font-size-400);
  line-height: var(--line-height-500);
}

.episodecard p + p {
  margin-top: var(--space-05);
}

.episodecard .episode-image {
  float: left;
  margin-right: var(--space-base);
  margin-bottom: var(--space-25);
}
.episodecard .episode-crew-title {
  clear: both;
  color: var(--color-4);
  margin-top: var(--space-05);
  font-size: var(--font-size-base);
  line-height: var(--line-height-400);
  font-weight: 800;
  padding-block: var(--space-05);
}

.episodecard .episode-card-actions {
  position: absolute;
  bottom: -1.5rem;
  right: 0;
}

.episodecard .episode-card-actions {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 1.5rem;
}

.episodecard .episode-card-actions > * {
  flex: 0 0 0;
}
