/*

Goal: establish a mutual vocabulary for setting layouts, box-treatments and color-thenes on content-objects

Method:
1. When possible use the same markup for content-objects
2. When this is not possoble the same (or similar?) classNames should be applied but have other css-rules?

ingen box.stil just nu alltid padding

slideshow 
.enter med animation som animerar fram slide
.exit med animattion som animerar bort slide

<björnblock className="layout--a  theme--red-on-white">
<eyebrow className="dymo theme--red-on-white">
<heading>
<editorial>
<cta>

.theme--red-on-white {
    background-color: red;
    color: white;
}

*/

.contentobject {
  display: grid;
  grid-template-columns: var(--grid--inner);
  grid-column-gap: var(--grid-gap);
  justify-content: center;
  align-items: center;
  grid-template-rows: 1fr;
}

.contentobject .image .img {
  transform: rotate(-5deg);
  width: 100%;
  aspect-ratio: 1 / 1;
  display: block;
  object-fit: cover;
}
.contentobject .textbox > * + * {
  margin-top: var(--space-base);
}

.contentobject .textbox {
  grid-column: g / o;
  grid-row: 1;
  z-index: 5;
  /* should we have this? */
  padding: var(--space-2);

  background-color: var(--color-background);
  color: var(--color-foreground);
}

@media only screen and (min-width: 801px) {
  /* layout A */
  .contentobject.layout--a .image {
    grid-column: c / i;
    grid-row: 1;
  }

  .contentobject.layout--a .textbox {
    grid-column: h / o;
    grid-row: 1;
    z-index: 5;
  }

  /* layout B */

  .contentobject.layout--b .image {
    grid-column: i / o;
    grid-row: 1;
  }

  .contentobject.layout--b .textbox {
    grid-column: c / k;
    grid-row: 1;
    z-index: 5;
  }
}

.contentobject .dymo {
}
.contentobject .textbox .button {
  margin-top: var(--space-2);
}

@media only screen and (max-width: 480px) {
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  /*
  .contentobject .image {
    grid-column: b / h;
  }
  .contentobject .textbox {
    grid-column: f/ p;
    padding-right: 1.25rem;
    box-sizing: border-box;
  }
*/
}
@media only screen and (min-width: 769px) {
}
@media only screen and (max-width: 480px) {
  .contentobject .image {
    grid-column: b / -1;
    grid-row: 1 / span 1;
    align-self: start;
  }
  .contentobject .image .img {
  }
  .contentobject .textbox {
    grid-column: c / n;
    grid-row: 1 / span 1;
    z-index: 5;
    align-self: end;
    padding-bottom: var(--space-1);
  }
}

@media only screen and (min-width: 481px) and (max-width: 800px) {
  .contentobject .image {
    grid-column: c / o;
    grid-row: 1 / span 1;
    align-self: start;
  }
  .contentobject .image .img {
  }
  .contentobject .textbox {
    grid-column: d / n;
    grid-row: 1 / span 1;
    z-index: 5;
    align-self: end;
    padding-bottom: var(--space-1);
  }
}

/*

  a   b   c   d   e   f   g   h   i   j   k   l   m   n   o
  1   2   3   4   5   6   7   8   9   10  11  12  13  14  15 
          -   -   -   -   -   -
                          -   -   -   -   -   -   -   -   -

                                      -   -   -   -   -   -
          -   -   -   -   -   -   -   -   -


 */

.co-feature {
  padding: 3rem 0;
  grid-column: 1 / -1;
  background-size: cover;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}

/* when used in a section*/
.co-feature .contentobject {
  grid-column: b / p;
}

@media only screen and (max-width: 480px) {
  .co-feature .contentobject .image {
    grid-column: b / -1;
    grid-row: 1 / span 1;
    align-self: start;
  }
  .co-feature .contentobject .image .img {
  }
  .co-feature .contentobject .textbox {
    grid-column: c / n;
    grid-row: 2 / span 1;
    z-index: 5;
    margin-top: -5rem;
    align-self: end;
    padding-bottom: var(--space-1);
  }
}

@media only screen and (min-width: 481px) and (max-width: 800px) {
  .co-feature .contentobject .image {
    grid-column: c / o;
    grid-row: 1 / span 1;
    align-self: start;
  }
  .co-feature .contentobject .image .img {
  }
  .co-feature .contentobject .textbox {
    grid-column: d / n;
    grid-row: 2 / span 1;
    z-index: 5;
    margin-top: -5rem;
    align-self: end;
    padding-bottom: var(--space-1);
  }
}
