/* FRINGE POP */
:root {
  /* prettier-ignore */
  --clip-fringe: polygon(
    0 0,
    10% 0,
    10% 8px,
    20% 8px,
    20% 4px,
    30% 4px,
    30% 0,
    40% 0,
    40% 12px,
    50% 12px,
    50% 0,
    60% 0,
    60% 6px,
    70% 6px,
    70% 12px,
    80% 12px,
    80% 0px,
    90% 0px,
    90% 6px,
    100% 6px,
    100% calc(100% - 6px), /* bottom right corner */
    90% calc(100% - 6px), 

    90% 100%,
    80% 100%,

    80% calc(100% - 12px),
    70% calc(100% - 12px),

    70% calc(100% - 6px),
    60% calc(100% - 6px),

    60% 100%,
    50% 100%,

    50% calc(100% - 12px),
    40% calc(100% - 12px),

    40% calc(100% - 6px),
    30% calc(100% - 6px),

    30% calc(100% - 12px),
    20% calc(100% - 12px),

    20% calc(100% - 6px),
    10% calc(100% - 6px),
  
    10% 100%,
    0% 100%
  );
}
.popup-shim {
  position: fixed;
  z-index: 2200;
  background: hsl(270, 33%, 19%, 0.75);
  backdrop-filter: blur(4px);
  padding-top: 6rem;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.fringe-pop {
  flex: 1 1 1;
  width: min(100vw - 2rem, 24rem);
  margin: 2vh auto auto auto auto;
  z-index: 2400;
  color: var(--color-foreground);
  position: relative;
  filter: drop-shadow(0 0 3rem rgba(0, 0, 0, 0.75));
}
.fringe-pop .pop-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1000;
}

.pop-title {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: var(--font-size-800);
  line-height: 1.5rem;
  font-weight: 700;
}

.fringe-pop .pop-header-buttons {
  position: absolute;
  top: -0.75rem;
  right: 0.75rem;
  z-index: 1000;
}

.fringe-pop .pop-footer {
  position: absolute;
  bottom: 0.75rem;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1000;
}

.fringe-pop .pop-footer-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.fringe-pop .pop-footer-buttons > * {
  flex: 0 0 0;
}
.fringe-pop .pop-close-button .button {
}
.pop-image {
  width: 100%;
}
.fringe {
  max-height: 72vh; /* ! Magic number*/

  background: var(--color-background);
  overflow-y: auto;
  overflow-x: hidden;

  /* prettier-ignore */
  -webkit-clip-path: var(--clip-fringe);
  clip-path: var(--clip-fringe);
}

.fringe-pop .intro {
  padding: 0 1.5rem;
  margin-top: -3rem;
}
.fringe-pop .pop-payload {
  padding: 0 1.5rem;
  margin-top: 0.75rem;
  padding-bottom: 3rem;
  font-weight: normal;
}
/*
.fringe-pop .pop-payload > * + *,
.fringe-pop .pop-payload .dymo > * + * {
  margin-top: 0.75rem;
}
*/
.fringe-pop {
  --hero-heading-font-size: 2.25rem;
  --hero-heading-line-height: 2.25rem;
}
.fringe-pop .hero-heading {
  margin-top: -7rem;
  margin-bottom: 0.75rem;
}

.fringe-pop .pop-payload input {
  display: block;
  width: 100%;
}
