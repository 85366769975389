code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero-heading {
  font-size: var(--hero-heading-font-size);
  font-weight: 900;
  line-height: var(--hero-heading-line-height);
  text-shadow: var(--hero-heading-shadow);
  color: white;
  margin-bottom: var(--space-2);
}

.hh-white {
  color: white;
}
.hh-color-1 {
  color: var(--color-1);
}

.hh-color-4 {
  color: var(--color-4);
}

.hh-color-5 {
  color: var(--color-5);
}

/* feature sections */

.feature {
  padding: 3rem 0;
  grid-column: 1 / -1;
  background-size: cover;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}

.feature .image {
  aspect-ratio: 1 / 1;
  align-self: center;
}
.feature .image .img {
  display: block;
  width: 100%;
}
.feature .textbox {
  align-self: center;
}

.feature .image {
  grid-column: b / i;
  grid-row: 1;
}
.feature .textbox {
  grid-column: c / o;
  grid-row: 1;
  z-index: 5;
}

/* feature--center */

.feature--center {
}
.feature--fiftyfifty-text-right .image .img {
  transform: rotate(-5deg);
}

.feature--fiftyfifty-text-left .image .img {
  transform: rotate(5deg);
}

@media only screen and (min-width: 768.1px) {
  .feature--center .image {
    grid-column: b / i;
    grid-row: 1;
  }
  .feature--center .textbox {
    grid-column: e / k;
    grid-row: 1;
    z-index: 5;
  }

  .feature--fiftyfifty-text-right .image {
    grid-column: b / i;
    grid-row: 1;
  }

  .feature--fiftyfifty-text-right .textbox {
    grid-column: i / o;
    grid-row: 1;
  }

  .feature--fiftyfifty-text-left .image {
    grid-column: i / p;
    grid-row: 1;
  }

  .feature--fiftyfifty-text-left .textbox {
    grid-column: c / i;
    grid-row: 1;
  }
}

@media only screen and (max-width: 480px) {
  .feature--fiftyfifty-text-right .image,
  .feature--fiftyfifty-text-left .image,
  .feature--center .image {
    grid-column: b / p;
    grid-row: 1;
    z-index: 2;
    margin-bottom: 1.5rem;
  }

  .feature--center .image {
    display: none;
  }
  .feature--fiftyfifty-text-left .textbox,
  .feature--fiftyfifty-text-right .textbox,
  .feature--center .textbox {
    grid-column: b / p;
    grid-row: 2;
    z-index: 4;
    margin-top: -5rem;
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .feature--fiftyfifty-text-right .image {
    grid-column: b / h;
  }
  .feature--fiftyfifty-text-right .textbox {
    grid-column: h / o;
  }

  .feature--fiftyfifty-text-left .image {
    grid-column: j / p;
  }

  .feature--fiftyfifty-text-left .textbox {
    grid-column: c / j;
  }

  .feature--center .image {
    grid-column: c / o;
    grid-row: 1;
    display: none;
  }
  .feature--center .textbox {
    grid-column: c / m;
    grid-row: 1;
    z-index: 5;
  }
}

/* textfield 3d */

.inset-3d {
  padding: 0.5rem;
  box-shadow: inset 0 0.5rem 0 0 rgba(0, 0, 0, 0.25);
  border: 1px solid black;
  color: black;
  font-size: var(--font-size-base);
  line-height: var(--leading-base);
  font-weight: normal;
}

.inset-3d:focus {
  outline: 0.25rem solid #0cf;
}

.hero-signup {
  display: flex;
  gap: 0.75rem;
  margin-top: 1.5rem;

  flex-wrap: nowrap;
}

.hero-signup .email {
  flex-grow: 1;
}
.hero-signup .email input {
  width: 100%;
  display: block;
}

.hero-signup .button {
  flex-grow: 0;
}

.skew-segment {
  padding: 72px 0;
  clip-path: polygon(0% 0%, 100% 2.5vw, 100% 100%, 0% calc(100% - 2.5vw));
  overflow: hidden;
  margin-top: -3rem;
}

.skew-segment-2 {
  padding: 72px 0;
  clip-path: polygon(0% 2.5vw, 100% 0, 100% calc(100% - 2.5vw), 0% 100%);
  overflow: hidden;
}

/* faq section */

.faq-section {
  padding: 3rem 0;
  grid-column: 1 / -1;
  background-size: cover;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}

.crew-section {
  padding: 3rem 0;
  grid-column: 1 / -1;
  background-size: cover;

  display: grid;
  grid-template-columns: var(--grid--receptet);
  grid-column-gap: var(--grid-gap);
}

.spacer-0 {
  height: 0;
}

.spacer-5 {
  height: 3rem;
}
.feature--login {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 75vh;
  padding-top: 2rem;
}
